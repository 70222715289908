
class HeaderTransition {
  constructor(options = {}) {
    this.options = {
      headerId: 'page-header',
      bgId: 'web_bg',
      maxBlur: 50,
      minOpacity: 0.8,
      transitionDuration: 300,
      ...options
    };

    this.header = null;
    this.webBg = null;
    this.initialized = false;

    this.init();
  }

  init() {
    if (this.initialized) return;

    this.header = document.getElementById(this.options.headerId);
    this.webBg = document.getElementById(this.options.bgId);

    if (!this.header) {
      console.warn(`Header element with id "${this.options.headerId}" not found`);
      return;
    }

    this.addTransitionStyles();

    this.handleScroll = this.handleScroll.bind(this);
    window.addEventListener('scroll', this.handleScroll, { passive: true });

    this.initialized = true;

    this.handleScroll();
  }

  addTransitionStyles() {
    const style = document.createElement('style');
    style.textContent = `
      #${this.options.headerId} .pl-container {
        will-change: transform, filter, opacity;
        transition: filter ${this.options.transitionDuration}ms ease-out,
                   opacity ${this.options.transitionDuration}ms ease-out;
      }
      
      #${this.options.bgId} {
        transition: filter ${this.options.transitionDuration}ms ease-out,
                   opacity ${this.options.transitionDuration}ms ease-out;
      }
    `;
    document.head.appendChild(style);
  }

  handleScroll() {
    if (!this.header) return;

    const headerHeight = this.header.offsetHeight;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const scrollProgress = Math.min(scrollTop / headerHeight, 1);

    const blurAmount = Math.min(scrollProgress * this.options.maxBlur, this.options.maxBlur);
    const opacity = 1 - scrollProgress;

    const container = this.header.querySelector('.pl-container');
    if (container) {
      container.style.filter = `blur(${blurAmount}px)`;
      container.style.opacity = opacity;
    }

    if (this.webBg) {
      this.webBg.style.filter = `blur(${blurAmount}px)`;
      let bg_opacity = opacity;
      if (bg_opacity < this.options.minOpacity) {
        bg_opacity = this.options.minOpacity;
      }
      this.webBg.style.opacity = bg_opacity;
    }
  }

  destroy() {
    if (!this.initialized) return;

    window.removeEventListener('scroll', this.handleScroll);
    this.initialized = false;
    this.header = null;
    this.webBg = null;
  }

  refresh() {
    this.destroy();
    this.init();
  }
}

const defaultConfig = {
  headerId: 'page-header',
  bgId: 'web_bg',
  maxBlur: 50,
  minOpacity: 0.8,
  transitionDuration: 400
};

let headerTransition = null;

function initHeaderTransition(config = {}) {
  if (headerTransition) {
    headerTransition.destroy();
  }
  headerTransition = new HeaderTransition({
    ...defaultConfig,
    ...config
  });
}

document.addEventListener('DOMContentLoaded', () => {
  initHeaderTransition();
});

document.addEventListener('pjax:complete', () => {
  if (headerTransition) {
    headerTransition.refresh();
  } else {
    initHeaderTransition();
  }
});

window.HeaderTransition = HeaderTransition;
window.initHeaderTransition = initHeaderTransition;